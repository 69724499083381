import { useState } from 'react';
import { axiosService } from '../services/axiosServices';


const useRequest = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getHeader = ()=>{
    let headers =  {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };
    if(localStorage.getItem("token")){
      headers['Authorization'] = `Bearer ${localStorage.getItem("token")}`;

    }

    let config = {
      headers,
    };
    return config;
  }

  const automaticScrapping = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosService.post('/api/automatic-scrapping',{},getHeader());
      return response.data;
    } catch (err) {
        console.log("error", error);
      setError(err.response ? err.response.data.message : 'Error en el registro');
    } finally {
      setLoading(false);
    }
  };

  const updateAuction = async (id,data) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosService.put(`/api/auction/${id}`,data,getHeader());
      return response.data;
    } catch (err) {
        console.log("error", error);
      setError(err.response ? err.response.data.message : 'Error en el registro');
    } finally {
      setLoading(false);
    }
  };

  const deleteAuction = async (id,data) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosService.delete(`/api/auction/${id}`,getHeader());
      return response.data;
    } catch (err) {
        console.log("error", error);
      setError(err.response ? err.response.data.message : 'Error en el registro');
    } finally {
      setLoading(false);
    }
  };

  const getAuctions = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosService.get('/api/auctions',getHeader());
      return response.data;
    } catch (err) {
        console.log("error", error);
      setError(err.response ? err.response.data.message : 'Error en el registro');
    } finally {
      setLoading(false);
    }
  };

  const getAuctionsFavorite = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosService.get('/api/auctions/favorite',getHeader());
      return response.data;
    } catch (err) {
        console.log("error", error);
      setError(err.response ? err.response.data.message : 'Error en el registro');
    } finally {
      setLoading(false);
    }
  };

  return { loading, error,  automaticScrapping, getAuctions, getAuctionsFavorite, updateAuction, deleteAuction};
};

export default useRequest;
