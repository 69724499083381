import React, { useEffect, useState } from 'react';
import useRequest from '../hooks/RequestHook';
import TablaRemates from "../components/TablaRemates"
import { useNavigate } from 'react-router-dom';
const DashboardPage = () => {
    const navigate = useNavigate();
    const [records, setRecords] = useState([]);
    const [recordsExcel,setRecordsExcel] = useState([]);
    const [recordsTable,setRecordsTable] = useState([]);
    const {automaticScrapping, getAuctions, getAuctionsFavorite, loading} = useRequest();
    const handleAutomaticScrapping = async ()=>{
        setRecords([]);
        setRecordsExcel([]);

        let data = await automaticScrapping();
        if(data?.length){
            setRecords(data);
            let dataExcel = await setDataExcel(data)
            setRecordsExcel(dataExcel);
            let dataTable = await setDataTable(data)
            setRecordsTable(dataTable);
        }
    }
    const handleGetAuctions = async ()=>{
        setRecords([]);
        setRecordsExcel([]);
        let data = await getAuctions();
        if(data?.length){
            setRecords(data);
            let dataExcel = await setDataExcel(data)
            setRecordsExcel(dataExcel);
            let dataTable = await setDataTable(data)
            setRecordsTable(dataTable);
        }
    }

    const handleGetAuctionsFavorite = async ()=>{
        setRecords([]);
        setRecordsExcel([]);
        let data = await getAuctionsFavorite();
        if(data?.length){
            setRecords(data);
            let dataExcel = await setDataExcel(data)
            setRecordsExcel(dataExcel);
            let dataTable = await setDataTable(data)
            setRecordsTable(dataTable);
        }
    }


    const setDataExcel = async(data=[])=>{
        let totalminino = 0, totalcompra = 0, totalcomision = 0, totalgastos = 0; 
        let newData = data.map( e =>{

            let newElement = {};
            
            if(e?.id){
                newElement["id"]=e?.id??0;
            }
            newElement["fecha de publicacion"]=e?.fecha_publicacion?.replace("Publicado:", "")??'';
            newElement["n juzgado "]=e?.juzgado_numero??'';
            newElement["juzgado "]=e?.juzgado??'';
            newElement["fecha de remate"]=e?.fecha_de_remate??'';
            newElement["comuna"]=e?.comuna??'';
            newElement["propiedad"]=e?.tipo_propiedad??'';
            newElement["derecho"]=e?.es_derecho??'';
            newElement["minimo"]= (e?.minimo !== "")?parseFloat(e?.minimo?.replace(/\./g, '').replace('$', '')):0;

            newElement["precio de compra"]=(e?.precio_compra !== "")?e?.precio_compra:0;
            newElement["precio de comisión"]=(e?.precio_comision !== "")?e?.precio_comision:0;
            newElement["precio de gastos"]=(e?.precio_gastos !== "")?e?.precio_gastos:0;
            newElement["rol causa"]=e?.rol_causa??'';
            newElement["rol de propiedad"]=e?.rol_propiedad??'';
            newElement["texto publicado"]=e?.publication_text??'';
            
            return newElement;
            
        })
        for (let index = 0; index < newData.length; index++) {
            totalminino=parseFloat(totalminino+newData[index]["minimo"]);
            totalcompra=parseFloat(parseFloat(totalcompra)+parseFloat(newData[index]["precio de compra"]));
            totalcomision=parseFloat(parseFloat(totalcomision)+parseFloat(newData[index]["precio de comisión"]));
            totalgastos= parseFloat(parseFloat(totalcomision)+parseFloat(newData[index]["precio de gastos"]));
        }
        let final_element = {}
        if(newData[0]?.id)
            final_element["id"]="";
        final_element["fecha de publicacion"]="";
        final_element["n juzgado "]="";
        final_element["juzgado "]="";
        final_element["fecha de remate"]="";
        final_element["comuna"]="";
        final_element["propiedad"]="";
        final_element["derecho"]="Totales";
        final_element["minimo"]= totalminino;
        final_element["precio de compra"]=totalcompra;
        final_element["precio de comisión"]=totalcomision;
        final_element["precio de gastos"]=totalgastos;
        final_element["rol causa"]="";
        final_element["rol de propiedad"]="";
        newData.push(final_element)
       
        return newData;
    }

    const setDataTable = async(data=[])=>{
        let totalminino = 0, totalcompra = 0, totalcomision = 0, totalgastos = 0; 
        let newData = data.map( e =>{

            let newElement = {};
            
            if(e?.id){
                newElement["id"]=e?.id??0;
            }
            newElement["fecha de publicacion"]=e?.fecha_publicacion?.replace("Publicado:", "")??'';
            newElement["n juzgado "]=e?.juzgado_numero??'';
            newElement["juzgado "]=e?.juzgado??'';
            newElement["fecha de remate"]=e?.fecha_de_remate??'';
            newElement["comuna"]=e?.comuna??'';
            newElement["propiedad"]=e?.tipo_propiedad??'';
            newElement["derecho"]=e?.es_derecho??'';
            newElement["minimo"]= (e?.minimo !== "")?parseFloat(e?.minimo?.replace(/\./g, '').replace('$', '')):0;

        /*
            newElement["precio de compra"]=(e?.precio_compra !== "")?e?.precio_compra:0;
            newElement["precio de comisión"]=(e?.precio_comision !== "")?e?.precio_comision:0;
            newElement["precio de gastos"]=(e?.precio_gastos !== "")?e?.precio_gastos:0;
        */
            newElement["rol causa"]=e?.rol_causa??'';
            newElement["rol de propiedad"]=e?.rol_propiedad??'';
            return newElement;
            
        })
        for (let index = 0; index < newData.length; index++) {
            totalminino=parseFloat(totalminino+newData[index]["minimo"]);
            //totalcompra=parseFloat(parseFloat(totalcompra)+parseFloat(newData[index]["precio de compra"]));
            //totalcomision=parseFloat(parseFloat(totalcomision)+parseFloat(newData[index]["precio de comisión"]));
            //totalgastos= parseFloat(parseFloat(totalcomision)+parseFloat(newData[index]["precio de gastos"]));
        }
        let final_element = {}
        if(newData[0]?.id)
            final_element["id"]="";
        final_element["fecha de publicacion"]="";
        final_element["n juzgado "]="";
        final_element["juzgado "]="";
        final_element["fecha de remate"]="";
        final_element["comuna"]="";
        final_element["propiedad"]="";
        final_element["derecho"]="Totales";
        final_element["minimo"]= totalminino;
        /*
        final_element["precio de compra"]=totalcompra;
        final_element["precio de comisión"]=totalcomision;
        final_element["precio de gastos"]=totalgastos;
        */
        final_element["rol causa"]="";
        final_element["rol de propiedad"]="";
        newData.push(final_element)
       
        return newData;
    }

    const logOut = ()=>{
        localStorage.removeItem("token");
        navigate('/login');
    }

    useEffect(()=>{handleGetAuctions()},[])

  return (
    <div className="flex min-h-full  flex-col bg-gray-100">
      <header className="bg-blue-600 text-white p-4 flex w-full">
        <h1 className="text-xl">DashboardPage</h1>
      </header>
      <div className="flex flex-1">
        <aside className="w-64 bg-white shadow-md p-4">
          <h2 className="text-lg font-semibold">Menú</h2>
          <ul>
            <li className="py-2 hover:bg-gray-200"onClick={handleGetAuctions}><p className='hover:font-bold'>Inicio</p></li>
            <li className="py-2 hover:bg-gray-200" onClick={handleGetAuctionsFavorite}><p className='hover:font-bold'>Favoritos</p></li>
            <li className="py-2 hover:bg-gray-200" onClick={handleAutomaticScrapping}><p className='hover:font-bold'>Scrapping</p></li>
            <li className="py-2 hover:bg-gray-200" onClick={logOut}><p className='hover:font-bold'>Cerrar sesión</p></li>
          </ul>
        </aside>
        <main className="flex-1 p-4 w-full">
            <h2 className="text-2xl font-semibold">Bienvenido al Dashboard</h2>
            <div className=' flex w-full mt-4 overflow-auto'>
                <TablaRemates 
                    recordsExcel={recordsExcel} 
                    recordsTable={recordsTable}
                    records={records}
                    loading={loading}
                    reloadAction={()=>handleGetAuctions()}
                />
            </div>
        </main>
      </div>
    </div>
  );
};

export default DashboardPage;
