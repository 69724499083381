import React, { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';




const ModalForm = ({ isOpen, onClose, data, onSave }) => {
  const [formData, setFormData] = useState(data);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    console.log("formData", formData);
    e.preventDefault();
    onSave(formData);
    onClose();
  };

  const labelKey = (key)=>{
    let newKey = key.charAt(0).toUpperCase() + key.slice(1);
    return newKey.replace("_"," ");
  }

  const typeKey = (key)=>{
    return ["precio_compra","precio_comision","precio_gastos"].includes(key)?"number":"text";
  }

  const disabledKey = (key, value)=>{
    return ['id', 'publication_text'].includes(key) && ![undefined, '', null].includes(value);
  }

  const divClassKey = (key)=>{
    return ['publication_text'].includes(key)?"mb-4 min-w-80 col-span-3":"mb-4 min-w-80";
  }

  useEffect(()=>{setFormData(data)}, [data])

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto rounded bg-white p-6">
          <Dialog.Title className="text-lg font-medium">Editar Datos</Dialog.Title>
          <form onSubmit={handleSubmit}>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
              {Object.keys(data).map((key) => (
                <div key={key} className={divClassKey(key)}>
                  <label className="block text-sm font-medium text-gray-700" htmlFor={key}>
                    {labelKey(key)}                
                  </label>
                  {
                    ['publication_text'].includes(key)?
                    <textarea
                      name={key}
                      defaultValue={data[key]}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      rows={4}
                      cols={40}
                      disabled={disabledKey(key, data[key])}
                    />
                    :
                    ['favorite_record'].includes(key)?
                      <select 
                        name={key}
                        defaultValue={data[key]}
                        onChange={handleChange}
                      >
                        <option value={1} selected={data[key]==1}>
                          Si
                        </option>
                        <option value={0} selected={data[key]==0}>
                          No
                        </option>
                      </select>
                    : <input
                      type={typeKey(key)}
                      name={key}
                      id={key}
                      value={formData[key]}
                      defaultValue={data[key]}
                      onChange={handleChange}
                      disabled={disabledKey(key)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  }
                 
                </div>
              ))}
            </div>
            
            <div className="flex justify-end">
              <button
                type="button"
                className="mr-2 rounded bg-gray-300 px-4 py-2"
                onClick={onClose}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="rounded bg-blue-500 px-4 py-2 text-white"
              >
                Guardar
              </button>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default ModalForm;
