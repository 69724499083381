import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage"
import DashboadPageg from "./pages/DashboardPage"
import PrivateRoute from "./guards/PrivateRoute";
import RegisterPage from "./pages/RegisterPage";
function App() {
  return <Router>
    <Routes>

        <Route path="/login" element={<LoginPage/>}/>
        <Route path="/register" element={<RegisterPage/>}/>
        {/*rutas privadas*/}
        <Route exact path='/' element={<PrivateRoute/>}>
            <Route exact path='/' element={<DashboadPageg/>}/>
        </Route>
    </Routes>
  </Router>
}

export default App;