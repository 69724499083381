import { useState } from 'react';
import { axiosService } from '../services/axiosServices';


const useAuth = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);

  const register = async (name, email, password) => {
    console.log("asasd")
    setLoading(true);
    setError(null);
    try {
      const response = await axiosService.post('/api/register', { name, email, password, password_confirmation:password });
      setUser(response.data.user);
      return response.data;
    } catch (err) {
        console.log("error", error);
      setError(err.response ? err.response.data.message : 'Error en el registro');
    } finally {
      setLoading(false);
    }
  };

  const login = async (email, password) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosService.post('/api/login', { email, password });
      //setUser(response.data.user);
      return response.data;
    } catch (err) {
      setError(err.response ? err.response.data.message : 'Error en el inicio de sesión');
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    setUser(null);
    // Aquí puedes agregar lógica para cerrar sesión en el backend
  };

  return { loading, error, user, register, login, logout };
};

export default useAuth;
